import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  BuyHero,
  BuyLandingInfo,
  AboutOptions,
  BuyPropertySlider,
  SinglePropertyAdvice,
} from 'components/sections';
import { TripleImageSection } from 'components/blocks';
import buyPageMotion from 'motion/buy.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionSinglePropertyAdvice, functionSlider, tripleImageSectionScroll } from 'motion/buy.js';
import { indexScrollState} from 'motion/index.js';
import { functionAboutAccordion } from 'motion/about.js';

indexScrollState();


export default class BuyPage extends PureComponent {
  componentDidMount() {
    const enquiryType =
      this.props.data.pages.propertySearchForm.offerTypes.sales
      || 'Sales';

    // Set enquiry type to Sales on /buy
    if (typeof window !== 'undefined') {
      window.directStateMutate({
        enquiryType,
      });
    }
  }

  ctaClick = () => {
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }

  render() {
    const { data } = this.props;
    const {
      pages: { propertySearchForm, buy },
    } = data;
    const { pageTitle, faq, landing, pitch, developments, callToAction, seo} = buy;

    const  img1 = {src:pitch.second.img1, height:314, width: 456};
    const  img2 = {src:pitch.second.img2, height:386, width: 346};
    const  img3 = {src:pitch.second.img3, height:649, width: 416};


    return (
      <Layout onVisible={buyPageMotion}>
         <SEO title={seo.title} description={seo.meta} keywords={seo.keywords} />

        <BuyHero content={landing} propertySearchForm={propertySearchForm} />
        <BuyLandingInfo content={pitch.main} />
        <ViewportEnterAnimation offset={100} animation={tripleImageSectionScroll}>
          <TripleImageSection
            content={pitch.second}
            buttonAction={() => console.log('lets discuss')}
            imageBack={img1}
            imageMiddle={img2}
            imageFront={img3}
            imageBackalt={pitch.second.img1alt}
            imageMiddlealt={pitch.second.img2alt}                     
            imageFrontalt={pitch.second.img3alt}
          />
        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} animation={functionAboutAccordion}>
          <AboutOptions name="buy-page-faq" data={faq}  />
        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} animation={functionSlider}>
          <BuyPropertySlider
            content={developments}
          />
        </ViewportEnterAnimation>
      <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
        <SinglePropertyAdvice content={callToAction} onClick={this.ctaClick} />
      </ViewportEnterAnimation>

      </Layout>
    );
  }
}

export const query = graphql`
  query BuyPage {
    pages {
      propertySearchForm {
        title
        offerTypes {
          lettings
          sales
        }
        defaultCost {
          min
          max
        }
        form {
          labels {
            area
            apartment
            bedrooms
            includeNewHomes
            searchBtn
          }
          selects {
            apartment {
              value
              text
            }
            bedrooms {
              value
              text
            }
          }
        }
      }
      buy {
        pageTitle
        landing {
          title
          bodyText
          img
        }
        faq {
          title
          questions {
            question
            answer
          }
        }
        pitch {
          main {
            title
            paragraph1
            paragraph2
            paragraph3
            callToAction
            img
            imgalt
          }
          second {
            title
            paragraph1
            paragraph2
            callToAction
            img1
            img2
            img3
            img1alt
            img2alt
            img3alt
          }
        }
        developments {
          title
          description
        }
        callToAction {
          title
          subtitle
          btn
          img
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;
